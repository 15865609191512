/** SVG injector */
class SpriteLoader {

    /**
     * Inject an SVG sprite into a document
     * @param {string} path Path to the SVG sprite
     * @param {element} el The element to append the sprite to
     */
    constructor(path, el = document.body) {
        this.path = path;
        this.el = el;
    }

    append() {
        const ajax = new XMLHttpRequest();

        ajax.open("GET", this.path, true);
        ajax.send();

        ajax.onload = () => {

            const div = document.createElement("div");

            div.style.display = "none";
            div.innerHTML = ajax.responseText;

            document.body.insertBefore(div, this.el.childNodes[0]);
        };
    }
}

export default SpriteLoader;








