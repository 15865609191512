import $ from "jquery";

/**
 * Mega Menu Logic
 */
function megaMenu() {

    const elements = {
        nav: $("#MegaMenu"),
        toggles: $(".mega-menu__toggle"),
        body: $("body")
    };

    elements.toggles.on("click", () => {
        elements.nav.toggleClass("is-active");
        elements.body.toggleClass("nav-active");
    });

}

export { megaMenu };
